@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	padding: 0;
	margin: 0;
	/* font-family: ui-sans-serif, system-ui, -apple-system, Inter,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif; */
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	letter-spacing: 0.000001px;
	/* -webkit-font-smoothing: antialiased; */
	background-color: var(--bg-primary);
}

a {
	text-decoration: none;
	color: var(--color-link);
	cursor: pointer;
}

* {
	box-sizing: border-box;
}

@layer base {
	:root {
		--navigation-height: 4.8rem;
	}

	:root {
		--text-primary: 0 0 0;

		--bg-primary: 253 254 254;
		--bg-secondary: 255 255 255;
		--bg-tertiary: 253 254 254;
		--bg-navbar: 255 255 255;

		--color-primary: 0 0 0;
		--color-highlight: #e16767;
		--color-link: var(--blue-600);

		--border-primary: var(--gray-100);
		--border-stronger: var(--gray-200);
		--border-lighter: var(--gray-050);
		--border-secondary: var(--gray-200);

		--text-primary: var(--gray-850);
		--text-secondary: var(--gray-600);

		--white: #fff;
		--black: #000;

		--gray-050: #fafafa;
		--gray-100: #ebebeb;
		--gray-200: #e1e1e1;
		--gray-300: #c1c1c1;
		--gray-400: #a1a1a1;
		--gray-500: #818181;
		--gray-600: #616161;
		--gray-700: #414141;
		--gray-800: #2b2b2b;
		--gray-850: #1a1a1a;
		--gray-900: #111;
		--red-050: snow;
		--red-100: #ffe5e9;
		--red-200: #fbbfc7;
		--red-300: #ff909f;
		--red-400: #ff7082;
		--red-500: #ff455d;
		--red-600: #d92038;
		--red-700: #c11027;
		--red-800: #8f0718;
		--red-900: #341418;
		--orange-050: #fff8f3;
		--orange-100: #ffe8d8;
		--orange-200: #ffc59b;
		--orange-300: #fc9c66;
		--orange-400: #fd812d;
		--orange-500: #f35815;
		--orange-600: #b83a05;
		--orange-700: #962d00;
		--orange-800: #672002;
		--orange-900: #3c1403;
		--yellow-050: #fffbe4;
		--yellow-100: #fff1a8;
		--yellow-200: #fed54a;
		--yellow-300: #f2b600;
		--yellow-400: #d19f03;
		--yellow-500: #a78103;
		--yellow-600: #7d5903;
		--yellow-700: #5c4716;
		--yellow-800: #41320c;
		--yellow-900: #281e03;
		--green-050: #effff3;
		--green-100: #d7fbdf;
		--green-200: #a9ecb8;
		--green-300: #75db8c;
		--green-400: #40d763;
		--green-500: #27b648;
		--green-600: #13862e;
		--green-700: #19652a;
		--green-800: #10481d;
		--green-900: #0a2b13;
		--blue-050: #f3fbff;
		--blue-100: #ddf2ff;
		--blue-200: #a9dffe;
		--blue-300: #73c7f9;
		--blue-400: #47b7f8;
		--blue-500: #1e9de7;
		--blue-600: #0b6ec5;
		--blue-700: #144eb6;
		--blue-800: #0e3682;
		--blue-900: #08204e;
		--purple-050: #f9f8ff;
		--purple-100: #eeeaff;
		--purple-200: #d4c9fe;
		--purple-300: #b7a5fb;
		--purple-400: #a18bf5;
		--purple-500: #8467f3;
		--purple-600: #5e49af;
		--purple-700: #4b3990;
		--purple-800: #3e1f75;
		--purple-900: #27124a;
	}

	/* Now for the dark primary */
	@media (prefers-color-scheme: dark) {
		:root {
			--color-highlight: #e16767;

			--text-primary: 255 255 255;
			--color-primary: 255 255 255;

			--border-primary: var(--gray-800);
			--border-stronger: var(--gray-700);
			--border-lighter: var(--gray-850);

			--color-link: var(--blue-400);

			--bg-navbar: 17 17 17;
			--bg-primary: 17 17 17;
			--bg-secondary: 25 25 25; /* 24 24 27; */
		}
	}
}
